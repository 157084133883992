import { Column, Columns } from '@components/website/Columns';

import { ModuleBasicCardsType } from '../../../../types';
import { ModuleComponentType } from '../../../../types/module';
import SectionWrap from '../ModuleSection';
import BasicCard from './BasicCard';
import styles from './styles.module.scss';

const getCardStart = (index: number) => (index % 3) * 4 + 1;

const ModuleBasicCards: ModuleComponentType = ({ data }) => {
  const { items } = data as unknown as ModuleBasicCardsType;

  return (
    <SectionWrap className={styles.sectionWrap}>
      <Columns className={styles.columnPadding}>
        {items.map((item, i) => (
          <Column key={item._key} start={getCardStart(i)} span={4}>
            <BasicCard {...item} />
          </Column>
        ))}
      </Columns>
    </SectionWrap>
  );
};

export default ModuleBasicCards;
